<template>
	<div id="tutor">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">导师管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div
						class="font_14 nav-tab-item"
						@click="twacherTap(index)"
						:class="index === teacherIndex ? 'active' : ''"
						v-for="(item, index) in teacherMeun"
						:key="index"
					>
						{{ item.name }}
					</div>
				</div>

			</div>
			<div class="content">
				<div class="suguan">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
						</div>
						<div class="u-f-item">
							<el-select v-model="gradeId" placeholder="请选择年级" size="small" style="width: 120px;margin-right: 10px;" @change="gradecn1">
							   <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="time" @change="timeChange" type="daterange" size="small" range-separator="至" style="margin-right: 10px;" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
							<el-input placeholder="请输入导师姓名" size='small' siz prefix-icon="el-icon-search" v-model="key" style="width: 150px;margin-right: 10px;"></el-input>
							<el-button size='small' type="primary" @click="sosuo">筛选</el-button>
							<el-button size='small' @click="addScoreShow=true">添加得分</el-button>
							<el-button size='small' type="primary" @click="importScoreShow=true">导入</el-button>
							<el-button size='small' type="primary" @click="exports1">导出</el-button>
						</div>
					</div>
					<el-table
						:data="list"
						height="94%"
						style="width: 100%;border: 1px solid #EEEEEE;"
					>
						<el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
						<el-table-column label="导师" align="center" prop="tutor_name"></el-table-column>
						<el-table-column prop="grade_name" label="年级" align="center"></el-table-column>
						<el-table-column label="学生反馈得分" prop="feedback" align="center"></el-table-column>
						<el-table-column label="学生学业发展得分	" prop="development" align="center"></el-table-column>
						<el-table-column label="总分" prop="total_score" align="center"></el-table-column>
						<el-table-column label="创建时间" align="center" prop="createtime"></el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="editScore(scope.row)" type="text" size="small">编辑</el-button>
								<el-button @click="delScore(scope.row.id)" type="text" size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>


			<!-- 添加配置 -->
			<el-dialog title="添加得分" :visible.sync="addScoreShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择年级"  style="width: 75%;"  @change="gradecn" >
							<el-option v-for="(item,index) in gradeList" :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							导师姓名
						</div>
						<el-select v-model="tutor_id" placeholder="请选择导师" filterable style="width: 75%;"  @change="tutorcn">
							<el-option v-for="(item,index) in tutorList" :key="index" :label="item.user_name" :value="item.user_id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学生反馈得分
						</div>
						<el-input-number v-model="feedback" :max="100" ></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学生学业发展得分
						</div>
						<el-input-number v-model="development" :max="100" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitadd">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑配置 -->
			<el-dialog title="编辑得分" :visible.sync="editScoreShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							年级
						</div>
						<el-select v-model="grade_id" placeholder="请选择年级"  style="width: 75%;"  @change="gradecn" >
							<el-option v-for="(item,index) in gradeList"  :key="index" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							导师姓名
						</div>
						<el-select v-model="tutor_id" placeholder="请选择导师" filterable style="width: 75%;"  @change="tutorcn">
							<el-option v-for="(item,index) in tutorList"  :key="index" :label="item.user_name" :value="item.user_id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学生反馈得分
						</div>
						<el-input-number v-model="feedback" :max="100" ></el-input-number>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							学生学业发展得分
						</div>
						<el-input-number v-model="development" :max="100" ></el-input-number>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitedit">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog title="导入得分" :visible.sync="importScoreShow" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							上传文件
						</div>
						<el-input v-model="file.name" disabled placeholder="请选择要上传的文件" style="width: 60%;margin-right:10px"></el-input>
						<el-button size="middle" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">选择文件</el-button>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="importScoreShow=false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitimport">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			importScoreShow:false,
			teacherMeun: [
				{ name: '约谈记录',id:1},
        { name: '约谈周期',id:5},
				{ name: '导师得分',id:2},
				{ name: '导师配置',id:3},
        { name: '导师自评',id:4}
			],
			teacherIndex: 1,
			page:1,
			limit:10,
			total:0,
			list:[],
			grade_id:'',
			addScoreShow:false,
			editScoreShow:false,
			feedback:0,
			development:0,
			grade_id:'',
			grade_name:'',
			tutor_id:'',
			tutor_name:'',
			gradeList:[],
			tutorList:[],
			key:'',
			file:'',
			gradeId:'',
			time:[]
		};
	},
	mounted() {
		this.tutorScoreList()
		this.getgradeList()
	},
	methods: {
		timeChange(e){
			this.page = 1;
			this.tutorScoreList()
		},
		gradecn1(){
			this.page = 1;
			this.tutorScoreList()
		},
		submitimport(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			this.$api.setting.importScore(formData).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('导入成功');
					this.importScoreShow = false
					this.tutorScoreList()
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		getFile(e){
			console.log(e)
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		delScore(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.tutorDels({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.tutorScoreList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		closeShow(){
			this.info()
			this.addScoreShow = false;
			this.editScoreShow = false;
		},
		// 获取年级列表
		getgradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
				}
			})
		},
		// 选择年级
		gradecn(e){
			this.gradeList.map(item => {
				if (item.id == this.grade_id) {
					this.grade_name = item.name;
				}
			});
			this.tutor_id = '';
			this.tutor_name = '';
			if(this.grade_id){
				this.gettutorList();
			}
		},
		// 选择导师
		tutorcn(e){
			this.tutorList.map(item => {
				if (item.user_id == this.tutor_id) {
					this.tutor_name = item.user_name;
				}
			});
		},
		exports1(){
			let data = {}
			if(this.gradeId){data.grade_id = this.gradeId}
			if(this.key){data.tutor_name = this.key}
			if(this.time.length){
				data.start_date = this.time[0];
				data.end_date = this.time[1];
			}
			this.$api.setting.tutorScoreExport(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('导出成功');
					location.href = res.data.data;
				}
			})

		},
		tutorScoreList(){
			let data = {
				page:this.page,
				pagesize:this.limit
			}
			if(this.gradeId){data.grade_id = this.gradeId}
			if(this.key){data.tutor_name = this.key}
			if(this.time.length){
				data.start_date = this.time[0];
				data.end_date = this.time[1];
			}
			this.$api.setting.tutorScoreList(data).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data;
					this.total = res.data.total;
				}
			})
		},
		twacherTap(index){
			this.teacherIndex = index;
			if(index==0){
				this.$router.push('/tutor')
			}else if(index==1){
				this.$router.push('/tutorScore')
			}else if(index==2){
				this.$router.push('/tutorSet')
			}else if(index==3){
				this.$router.push('/ziping')
			}
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.tutorScoreList();
		},
		handleClose(done){
			done()
			this.info()
		},
		submitadd(){
			let data = {
				grade_id:this.grade_id,
				grade_name:this.grade_name,
				tutor_id:this.tutor_id,
				tutor_name:this.tutor_name,
				feedback:this.feedback,
				development:this.development
			}
			this.$api.setting.scoreOpera(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.addScoreShow = false;
					this.info()
					this.tutorScoreList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		info(){
			this.grade_id = '';
			this.grade_name = '';
			this.tutor_id = '';
			this.tutor_name = '';
			this.feedback = '';
			this.development = '';
		},
		sosuo(){
			this.page = 1;
			this.tutorScoreList()
		},
		gettutorList(){
			this.$api.setting.tutorList({
				grade_id:this.grade_id
			}).then(res=>{
				if(res.data.code==1){
					this.tutorList = res.data.data;
				}
			})
		},
		editScore(e){
			this.grade_id = e.grade_id;
			this.grade_name = e.grade_name;
			this.gettutorList();
			this.tutor_id = e.tutor_id;
			this.tutor_name = e.tutor_name;
			this.feedback = e.feedback;
			this.development = e.development;
			this.id = e.id;
			this.editScoreShow = true;
		},
		submitedit(){
			let data = {
				id:this.id,
				grade_id:this.grade_id,
				grade_name:this.grade_name,
				tutor_id:this.tutor_id,
				tutor_name:this.tutor_name,
				feedback:this.feedback,
				development:this.development
			}
			this.$api.setting.tutorModify(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('修改成功');
					this.editScoreShow = false;
					this.info()
					this.tutorScoreList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss">
#tutor {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 15px;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 78vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 78vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 74vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 68vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
		.suguan{
			padding: 15px 50px 15px 50px;
			height: 70vh;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}

.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el_option {
	display: none !important;
}
</style>
